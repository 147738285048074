<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            class="login-image"
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Olá, bem-vindo a IMEDIATO! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Faça login em sua conta
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- client -->
              <b-form-group
                label="Código da empresa"
                label-for="login-client"
                class="label-bold"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cliente"
                  vid="client"
                  rules="required"
                >
                  <b-form-input
                    id="login-client"
                    v-model="userInstitution"
                    :state="errors.length > 0 ? false:null"
                    name="login-client"
                    placeholder="Código do cliente"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
                class="label-bold"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="seu-email@mail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="label-bold" for="login-password">Senha</label>
                  <b-link :to="{ name: 'forgot-password', params: { seedEmail: userEmail } }">
                    <small class="label-forgot">Esqueceu sua senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-2"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import useSanctum from '@/auth/sanctum/useSanctum'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userInstitution: this.getInstitution(),
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-imediato-logo.svg'),
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-imediato-logo.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    getInstitution() {
      return localStorage.getItem('userInstitution') || ''
    },
    setInstitution(value) {
      return localStorage.setItem('userInstitution', value)
    },
    async validationForm() {
      const success = this.$refs.loginValidation.validate()

      if (!success) return

      try {
        await this.$recaptchaLoaded()

        const token = await this.$recaptcha('login')

        const response = await useSanctum.login({
          institution: this.userInstitution,
          email: this.userEmail,
          password: this.password,
          recaptcha: token,
        })

        const userData = response.data
        userData.access_type = userData.user_access_type ? userData.user_access_type.slugname : 'operacional'
        userData.role = userData.roles.length ? userData.roles[0].name : 'institution'
        const ability = {
          operacional: [
            { action: 'read', subject: 'all' },
            { action: 'read', subject: 'Physician' },
            { action: 'create', subject: 'Physician' },
            { action: 'view', subject: 'Physician' },
            { action: 'edit', subject: 'Physician' },
            { action: 'edit', subject: 'PhysicianDuty' },
            { action: 'read', subject: 'Workplace' },
          ],
          gestor: [
            { action: 'manage', subject: 'all' },
          ],
        }
        userData.abilities = ability[userData.access_type]

        localStorage.setItem('userData', JSON.stringify(userData))
        this.setInstitution(this.userInstitution)
        this.$ability.update(ability[userData.access_type])

        this.$router.replace(getHomeRouteForLoggedInUser(userData.access_type))
          .then(() => {
            //
          })
      } catch (error) {
        const errors = (error.response && error.response.status === 422 && error.response.data.errors) || {}
        const errorCode = error.response && error.response.data && error.response.data.error_code
        if (errorCode === 1657582529) {
          this.$refs.loginValidation.setErrors({
            email: 'Usuário inativo',
          })
          return
        }
        if (Object.keys(errors).some(key => key === 'recaptcha')) {
          this.$refs.loginValidation.setErrors({
            password: 'Dispositivo não passou no teste de segurança',
          })
        } else {
          this.$refs.loginValidation.setErrors({
            email: 'Email ou Senha incorretos',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.label-bold {
  color: #3C3C3B;
  font-weight: 700;
}

.label-forgot {
  color: #e85d2a;
}
.login-image {
  max-width: 500px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
